import React from "react"

import teamMembers from "../components/teamMembers"
import TeamList from "../components/team-list"
import SEO from "../components/seo"
import Layout from "../components/layout"

const TeamPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Team"> </SEO>
      {/* The Team */}
      <section id="theteam" className="team" style={{ padding: 0, margin: 20, marginBottom: 80 }}>
        <div className="container">
          <div className="row">
            <div className="col col__12">
              <h2 className="title title--center">Meet The Team</h2>
              <h3 className="subtitle subtitle--center">
                The Talent Inside MangoChango
              </h3>
            </div>
          </div>
          <TeamList teamList={teamMembers}></TeamList>
        </div>
      </section>
      {/* The Team */}
    </Layout>
  )
}

export default TeamPage
